import { Box, BoxProps } from '@mui/material';
import { BoxTypeMap } from '@mui/system';
import { ElementType, ForwardedRef, useMemo } from 'react';

import { getSxArray } from '../../common/styles';

export type FlexBoxProps<TComponent extends ElementType = 'div'> = {
  column?: boolean;
  component?: TComponent;
  ref?: ForwardedRef<HTMLElement>;
} & BoxProps<TComponent>;

export function FlexBox<TComponent extends ElementType = BoxTypeMap['defaultComponent']>({
  column,
  sx,
  ...props
}: FlexBoxProps<TComponent>) {
  const boxProps = useMemo(
    () => ({
      ...props,
      sx: [Boolean(column) && { flexDirection: 'column' }, ...getSxArray(sx), { display: 'flex' }],
    }),
    [column, props, sx],
  );

  return <Box {...boxProps} />;
}
