import { useMemo } from 'react';

import { ELLIPSIS_TEXT_STYLES, styledProps } from '../../common/styles';
import { Link, LinkProps } from './Link';

export function TableCellLink({ children, ...props }: LinkProps) {
  const linkProps = useMemo(
    () =>
      styledProps(ELLIPSIS_TEXT_STYLES, {
        ...props,
        ...(!props.title && typeof children === 'string' && { title: children }),
      }),
    [children, props],
  );

  return <Link {...linkProps}>{children}</Link>;
}
