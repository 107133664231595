import { ReactNode, useMemo } from 'react';

import { PAGES } from '../../common/pages';
import { useGetRedirectionUrl } from '../../hooks/useGetRedirectionUrl';
import { TableCellLink } from './TableCellLink';

export function TableCompanyLink({
  children,
  companyId,
  pathname = PAGES.company.path,
  tab = 0,
}: {
  children: ReactNode;
  companyId: string;
  pathname?: string;
  tab?: number;
}) {
  const getRedirectionUrl = useGetRedirectionUrl();

  const href = useMemo(
    () =>
      getRedirectionUrl({
        pathname,
        searchParams: new URLSearchParams({ companyId, ...(tab > 0 && { tab: String(tab) }) }),
      }),
    [companyId, getRedirectionUrl, pathname, tab],
  );

  return <TableCellLink href={href}>{children}</TableCellLink>;
}
