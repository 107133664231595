import { OpenInNew } from '@mui/icons-material';
import { ReactNode } from 'react';

import { Button, ButtonLinkProps, ButtonProps } from './Button';

export function ExternalLink({
  label,
  linkProps,
  ...buttonProps
}: {
  label?: ReactNode;
  linkProps?: ButtonLinkProps;
} & Pick<ButtonProps, 'onClick' | 'title'>) {
  return (
    <>
      {label}
      <Button
        linkProps={linkProps && { target: '_blank', ...linkProps }}
        Icon={OpenInNew}
        size="small"
        sx={{ ml: 1 }}
        {...buttonProps}
      />
    </>
  );
}
