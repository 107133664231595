import NextImage, { ImageProps } from 'next/legacy/image';
import { useMemo } from 'react';

import { useConfigContext } from '../../contexts/ConfigContext';

const PREFIX = 'cdn:';

export function Image(props: ImageProps) {
  const {
    cdn: { imagesPath, url },
  } = useConfigContext();

  const imageProps = useMemo(() => {
    const { src, ...rest } = props;

    if (typeof src === 'string' && src.startsWith(PREFIX)) {
      return { src: src.replace(PREFIX, `${url}/${imagesPath}/`), ...rest };
    }

    return props;
  }, [imagesPath, props, url]);

  return <NextImage {...imageProps} />;
}
