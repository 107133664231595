import { Box, BoxProps } from '@mui/material';
import { useMemo } from 'react';

import { styledProps } from '../../common/styles';

export type SpanProps = Omit<BoxProps, 'component'> & { isStrong?: boolean | undefined };

export function Span({ isStrong, ...props }: SpanProps) {
  const boxProps = useMemo(
    () => styledProps({ fontWeight: isStrong ? 900 : 'inherit' }, { ...props, component: 'span' }),
    [isStrong, props],
  );

  return <Box {...boxProps} />;
}
