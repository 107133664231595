import { List, ListItem, ListItemText } from '@mui/material';
import { ReactNode } from 'react';

import { ELLIPSIS_TEXT_STYLES } from '../../common/styles';

export function TableCellList({ items }: { items: ReactNode[] }) {
  return (
    <List dense>
      {items.map((item, index) => (
        <ListItem disableGutters key={index}>
          <ListItemText sx={ELLIPSIS_TEXT_STYLES}>{item}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
