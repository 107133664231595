import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PermissionsScope } from '@sbiz/business';

import { useIsEditor } from '../../hooks/useIsEditor';
import { Button, ButtonProps } from './Button';
import { InfoTooltip } from './InfoTooltip';

export function RestrictedButton({
  isHiddenWhenUnauthorized,
  scope,
  ...buttonProps
}: { isHiddenWhenUnauthorized?: boolean; scope: PermissionsScope } & Omit<ButtonProps, 'disabled'>) {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ tooltip: t('tooltips.buttons.unauthorized') }), [t]);

  const isAuthorized = useIsEditor(scope);

  if (isAuthorized) {
    return <Button {...buttonProps} />;
  }

  return isHiddenWhenUnauthorized ? null : (
    <Box>
      <Button disabled {...buttonProps} />
      <InfoTooltip title={labels.tooltip} />
    </Box>
  );
}
