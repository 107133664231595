import { createContext, ReactNode, useContext } from 'react';

import { EMPTY_ASYNC_FUNCTION } from '@sbiz/common';

import { AuthUser } from '../common/session';
import { useAuthRedirection } from '../hooks/useAuthRedirection';
import { AuthUserMutator } from '../hooks/useAuthUser';

const AuthUserContext = createContext<{ authUser: AuthUser; mutate: AuthUserMutator }>({
  authUser: {} as AuthUser,
  mutate: EMPTY_ASYNC_FUNCTION,
});

export function AuthUserContextProvider({ children }: { children: ReactNode }) {
  const { authUser, isLoading, mutate, routeOverride } = useAuthRedirection();

  if (isLoading || routeOverride || !authUser) {
    return null;
  }

  return <AuthUserContext value={{ authUser, mutate }}>{children}</AuthUserContext>;
}

export function useAuthUserContext() {
  return useContext(AuthUserContext);
}
