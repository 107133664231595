import { useMemo } from 'react';

import { styledProps } from '../../common/styles';
import { Button, ButtonProps } from './Button';

export function RoundButton({ size, ...props }: { size: number } & Omit<ButtonProps, 'size'>) {
  const buttonProps = useMemo(
    () => styledProps({ borderRadius: '50%', height: size, minWidth: size, p: 0, width: size }, props),
    [props, size],
  );

  return <Button {...buttonProps} />;
}
