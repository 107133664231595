import { ReactNode, Ref } from 'react';

import { ELLIPSIS_TEXT_STYLES } from '../../common/styles';
import { Span } from './Span';

export function TableCellText(props: { children: ReactNode; ref?: Ref<unknown> }) {
  return (
    <Span
      sx={ELLIPSIS_TEXT_STYLES}
      title={typeof props.children === 'string' ? props.children : undefined}
      {...props}
    />
  );
}
