import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { SxArray, SxObject } from './types';

declare module '@mui/material/styles' {
  interface TypeBackground {
    primary?: string;
  }
}

export function getSxArray(sx?: SxArray | SxObject | ((theme: Theme) => SxObject)): SxArray {
  if (sx) {
    return (Array.isArray(sx) ? sx : [sx]) as SxArray;
  }

  return [];
}

export function styledProps<T extends { sx?: SxProps<Theme> } | undefined>(styles: SxProps<Theme>, props: T) {
  return { ...props, sx: [...getSxArray(styles), ...getSxArray(props?.sx)] } as T & { sx: SxArray };
}
