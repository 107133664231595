import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';

import { FeatureFlagName, PermissionsScope } from '@sbiz/business';
import { EMPTY_FUNCTION } from '@sbiz/common';

import { useApi } from '../common/api/hooks/useApi';
import { useAppTour } from '../common/api/hooks/useAppTour';
import {
  getPageTabs,
  Page,
  PAGES,
  PageTabDefinition,
  TAB_PARAM_NAME,
  TOUR_STEP_SEQUENCE,
  TourStep,
  TourStepId,
} from '../common/pages';
import { useGetAuthorizedItems } from '../hooks/useGetAuthorizedItems';
import { useQueryParams } from '../hooks/useQueryParams';
import { useRoute } from '../hooks/useRoute';
import { useAuthUserContext } from './AuthUserContext';
import { useFeatureFlagsContext } from './FeatureFlagsContext';

const BOUNDS_PARAM_NAME = 'tourBounds';
const INDEX_PARAM_NAME = 'tourStepIndex';

const TOUR_CLOSE_SCREEN = TOUR_STEP_SEQUENCE.at(-1) as TourStep;
const TOUR_SPLASH_SCREEN = TOUR_STEP_SEQUENCE.at(0) as TourStep;

export type TourContext = {
  closeTour: () => void;
  getPathname: (step: TourStep) => string | undefined;
  getStep: (stepId: TourStepId) => TourStep | undefined;
  isAvailable: boolean;
  isFullTour: boolean;
  nextTourStep: () => void;
  previousTourStep: () => void;
  startTour: (options?: { from?: TourStepId; to?: TourStepId }) => void;
  step?: TourStep;
  stepCount: number;
  stepIndex: number;
};

const TourContext = createContext<TourContext>({
  closeTour: EMPTY_FUNCTION,
  getPathname: () => undefined,
  getStep: () => undefined,
  isAvailable: false,
  isFullTour: false,
  nextTourStep: EMPTY_FUNCTION,
  previousTourStep: EMPTY_FUNCTION,
  startTour: EMPTY_FUNCTION,
  stepCount: TOUR_STEP_SEQUENCE.length - 2,
  stepIndex: -1,
});

export function TourContextProvider({ children }: { children: ReactNode }) {
  const [[boundsParam, stepIndexParam], setParams] = useQueryParams([
    BOUNDS_PARAM_NAME,
    INDEX_PARAM_NAME,
    TAB_PARAM_NAME,
  ] as const);
  const stepIndex = typeof stepIndexParam?.value === 'string' ? Number(stepIndexParam.value) : -1;

  const { post } = useApi('manager');
  const { data: appTour, mutate } = useAppTour({ storage: true });
  const { authUser } = useAuthUserContext();
  const featureFlags = useFeatureFlagsContext();
  const getAuthorizedItems = useGetAuthorizedItems();
  const [{ pathname, searchParams }, setRoute] = useRoute();

  const steps = useMemo(() => {
    const authorizedSteps = TOUR_STEP_SEQUENCE.slice(1, -1).filter((step) => {
      if ('page' in step) {
        const pageName = step.page;
        const page: Page = PAGES[pageName];
        const tab = getTab(step);

        const flags: FeatureFlagName[] = page?.featureFlag ? [page.featureFlag] : [];

        if (tab?.featureFlag) {
          flags.push(tab.featureFlag);
        }

        if (flags.some((flag) => !featureFlags?.[flag])) {
          return false;
        }

        const scopes: PermissionsScope[] = page?.permissionsScopes ? page.permissionsScopes : [];

        if (tab?.permissionsScope) {
          scopes.push(tab.permissionsScope);
        }

        if (scopes.length && scopes.every((scope) => !authUser.permissions[scope]?.read)) {
          return false;
        }
      }

      return true;
    });

    return [TOUR_SPLASH_SCREEN, ...authorizedSteps, TOUR_CLOSE_SCREEN] as const;
  }, [authUser.permissions, featureFlags]);

  const fullTourBounds = useMemo(() => ({ start: 0, end: steps.length - 1 }) as const, [steps]);

  const bounds = useMemo(() => {
    if (boundsParam?.value) {
      const [start, end] = boundsParam.value.split(',');
      return { start: Number(start), end: Number(end) };
    }

    return fullTourBounds;
  }, [boundsParam?.value, fullTourBounds]);
  const firstStepIndex = Math.max(1, bounds.start);
  const lastStepIndex = Math.min(steps.length - 2, bounds.end);

  const isFullTour = useMemo(
    () => bounds.start === fullTourBounds.start && bounds.end === fullTourBounds.end,
    [bounds, fullTourBounds],
  );

  const seenSteps = useMemo<Set<TourStepId>>(
    () => new Set(appTour?.seenSteps?.map(({ id }) => id)),
    [appTour?.seenSteps],
  );

  const getTabIndex = useCallback(
    (step: TourStep) => {
      const tabs = getTabs(step);

      if (tabs.length) {
        const authorizedTabs = getAuthorizedItems(tabs);
        return authorizedTabs.findIndex(({ tourStepId }) => tourStepId === step.id);
      }

      return -1;
    },
    [getAuthorizedItems],
  );

  const resetTour = useCallback(() => {
    const firstStep = steps[firstStepIndex];
    const stepPathname = getPathname(firstStep) ?? PAGES.company.path;
    const tabIndex = getTabIndex(firstStep);

    const urlSearchParams = new URLSearchParams(searchParams ?? undefined);

    for (const name of [BOUNDS_PARAM_NAME, INDEX_PARAM_NAME]) {
      urlSearchParams.delete(name);
    }

    if (tabIndex > -1) {
      urlSearchParams.set(TAB_PARAM_NAME, String(tabIndex));
    } else {
      urlSearchParams.delete(TAB_PARAM_NAME);
    }

    setRoute({ pathname: stepPathname, searchParams: urlSearchParams });
  }, [firstStepIndex, getTabIndex, searchParams, setRoute, steps]);

  const seeStep = useCallback(
    (stepId: TourStepId) => {
      if (appTour && !seenSteps.has(stepId)) {
        post('app-tour/seen-steps', { data: { id: stepId } });
        mutate(
          { ...appTour, seenSteps: [...appTour.seenSteps, { id: stepId, seenAt: new Date() }] },
          { revalidate: false },
        );
      }
    },
    [appTour, mutate, post, seenSteps],
  );

  const handleStepIndexChange = useCallback(
    (delta: number) => {
      const index = stepIndex + delta;

      if (index < bounds.start || index > bounds.end) {
        resetTour();
        return;
      }

      const indexParam = String(index);
      const nextStep = steps[index];
      const nextPathname = getPathname(nextStep) ?? pathname;
      const nextTabIndex = getTabIndex(nextStep);
      const nextTab = nextTabIndex > -1 ? String(nextTabIndex) : undefined;

      if (nextPathname !== pathname || nextTab !== searchParams?.get(TAB_PARAM_NAME)) {
        const urlSearchParams = new URLSearchParams(searchParams ?? undefined);

        urlSearchParams.set(INDEX_PARAM_NAME, indexParam);

        if (nextTab) {
          urlSearchParams.set(TAB_PARAM_NAME, nextTab);
        } else {
          urlSearchParams.delete(TAB_PARAM_NAME);
        }

        setRoute({ pathname: nextPathname, searchParams: urlSearchParams });
      } else {
        setParams({ [INDEX_PARAM_NAME]: indexParam });
      }

      if (delta > 0) {
        seeStep(nextStep.id);
      }
    },
    [
      bounds.end,
      bounds.start,
      getTabIndex,
      pathname,
      resetTour,
      searchParams,
      seeStep,
      setParams,
      setRoute,
      stepIndex,
      steps,
    ],
  );

  const closeTour = useCallback(() => {
    const delta = lastStepIndex + 1 - stepIndex;
    handleStepIndexChange(delta);
  }, [handleStepIndexChange, lastStepIndex, stepIndex]);

  const getStep = useCallback((stepId: TourStepId) => steps.find(({ id }) => id === stepId), [steps]);

  const startTour = useCallback(
    (options?: { from?: TourStepId; to?: TourStepId }) => {
      const fromIndex = options?.from ? steps.findIndex(({ id }) => id === options.from) : -1;
      const toIndex = options?.to ? steps.findIndex(({ id }) => id === options.to) : -1;

      const { start, end } = fullTourBounds;
      const bounds = { start: Math.max(fromIndex, start), end: toIndex > -1 && toIndex < end ? toIndex : end };

      const nextStep = steps[bounds.start];
      const tabIndex = getTabIndex(nextStep);

      setParams({
        [BOUNDS_PARAM_NAME]: `${bounds.start},${bounds.end}`,
        [INDEX_PARAM_NAME]: String(bounds.start),
        [TAB_PARAM_NAME]: tabIndex > -1 ? String(tabIndex) : null,
      });

      seeStep(nextStep.id);
    },
    [getTabIndex, fullTourBounds, seeStep, setParams, steps],
  );

  const value = useMemo(
    (): TourContext => ({
      closeTour,
      getPathname,
      getStep,
      isAvailable: true,
      isFullTour,
      nextTourStep: () => handleStepIndexChange(1),
      previousTourStep: () => handleStepIndexChange(-1),
      startTour,
      step: steps[stepIndex],
      stepCount: lastStepIndex - firstStepIndex + 1,
      stepIndex: stepIndex - firstStepIndex + 1,
    }),
    [closeTour, firstStepIndex, getStep, handleStepIndexChange, isFullTour, lastStepIndex, startTour, stepIndex, steps],
  );

  return <TourContext value={value}>{children}</TourContext>;
}

function getPathname(step: TourStep) {
  return step.page && PAGES[step.page].path;
}

function getTab(step: TourStep) {
  return getTabs(step).find(({ tourStepId }) => tourStepId === step.id);
}

function getTabs(step: TourStep): PageTabDefinition[] {
  return step.page ? getPageTabs(step.page) : [];
}

export function useTourContext() {
  return useContext(TourContext);
}
