import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import { useMemo } from 'react';

import { styledProps } from '../../common/styles';

export type ArrowDirection = 'up' | 'right' | 'down' | 'left';

export function Arrow({
  color,
  direction,
  size,
  ...props
}: { color?: string; direction: ArrowDirection; size?: number } & BoxProps) {
  const borderStyles = useMemo(() => {
    const px = `${size ?? 8}px`;
    const adjacentStyle = `${px} solid transparent`;
    const oppositeStyle = `${px} solid ${color ?? 'white'}`;

    switch (direction) {
      case 'up':
        return { borderBottom: oppositeStyle, borderLeft: adjacentStyle, borderRight: adjacentStyle };
      case 'right':
        return { borderBottom: adjacentStyle, borderLeft: oppositeStyle, borderTop: adjacentStyle };
      case 'down':
        return { borderLeft: adjacentStyle, borderRight: adjacentStyle, borderTop: oppositeStyle };
      case 'left':
        return { borderBottom: adjacentStyle, borderRight: oppositeStyle, borderTop: adjacentStyle };
    }
  }, [color, direction, size]);

  const boxProps = useMemo(() => styledProps({ height: 0, width: 0, ...borderStyles }, props), [borderStyles, props]);

  return <Box {...boxProps}></Box>;
}
